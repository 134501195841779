import { Box, Divider, Text, useStyles, useTheme } from '@chakra-ui/react'

export const customAssetSelectFormat = {
	Option: ({
		innerRef,
		innerProps,
		children,
		isFocused,
		isDisabled,
		data,
		selectProps: { size },
	}) => {
		const { item } = useStyles()
		const theme = useTheme()
		const {
			details: {
				asset_type_name: asset_first_name,
				operating_asset_phone_number,
			},
		} = data
		return (
			<Box
				role='button'
				borderRadius='sm'
				sx={{
					...item,
					w: '100%',
					textAlign: 'left',
					bg: isFocused ? theme.colors.gray[100] : 'transparent',
					cursor: 'pointer',
					fontSize: size,
					...(isDisabled && item._disabled),
				}}
				ref={innerRef}
				{...innerProps}
				{...(isDisabled && { disabled: true })}
			>
				{children}
				<Divider />
				{asset_first_name ? (
					<Text textTransform='capitalize'>{asset_first_name}</Text>
				) : null}
				{operating_asset_phone_number ? (
					<Text>{operating_asset_phone_number}</Text>
				) : null}
			</Box>
		)
	},
}
