/* eslint-disable react-hooks/exhaustive-deps */
import {
	Input,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	VStack,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useLanguageContext } from '../../../../../context/locale.context'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import { smartFormConst } from '../../../../../utils/action.constant'
import {
	getAddToCurrentDateUTC,
	getCurrentDateUTC,
} from '../../../../../utils/date.utils'
import {
	loadFinalValue,
	prepareFinalFieldValueForEdit,
} from '../../../../../utils/form.util'
import Select from '../../../../chakra-react-select'
import './style.scss'
/**
 * dataTypeID 82
 * dataTypeName  Conveyance
 *
 */
const ConveyanceField = ({
	field,
	isRequired,
	isDisabled = true,
	onValidate,
	isBulkEdit,
	fieldIndex,
	...rest
}) => {
	const {
		state: { fields: formFields },
		dispatch,
	} = useSmartFormContext()
	const {
		state: { locale },
	} = useLanguageContext()
	const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {}
	const { field_id } = field

	const [productCategories] = useState([
		{ label: 'Single', value: 1 },
		{ label: 'Multiple', value: 2 },
	])
	const [fromEdit, setFromEdit] = useState(false)
	const [isfromEdit, setIsFromEdit] = useState(false)
	const [productCat, setproductCat] = useState(null)
	const [productVariant, setproductVariant] = useState([])
	const [productTotal, setProductTotal] = useState(0)
	const [productWorkflowTypes, setProductWorkflowTypes] = useState([])

	useEffect(() => {
		if (
			!!fields[field_id] &&
			!!fields[field_id].field_value &&
			!!fields[field_id].field_value.isFromEdit
		) {
			const {
				productCatData,
				productVariantData,
				productTotalData,
				isFromEdit,
			} = fields[field_id].field_value
			if (!!productCatData && isFromEdit) {
				setFromEdit(isFromEdit)
				setIsFromEdit(isFromEdit)
				setproductCat(productCatData)
				setproductVariant(productVariantData)
				setProductTotal(productTotalData)
				onValidate(true)
			}
		}
	}, [isfromEdit])

	useEffect(() => {
		if (
			!!fields[field_id] &&
			!!fields[field_id].field_value &&
			!!fields[field_id].field_value.isFromEdit
		) {
			const {
				productCatData,
				productWorkflowData,
				productWorkflowTypeData,
				productVariantData,
				productTotalData,
				isFromEdit,
			} = fields[field_id].field_value

			if (!!productCatData && isFromEdit) {
				setFromEdit(isFromEdit)
				setIsFromEdit(isFromEdit)
				setproductCat(productCatData)
				setproductVariant(productVariantData)
				setProductTotal(productTotalData)
				onValidate(true)
			}
		}
	}, [fields[field_id]])

	useEffect(() => {
		if (!!productCat && !fromEdit) {
			onValidate(false)
		}
		if (!!productCat) {
			if (productCat?.value === 1) {
				setProductWorkflowTypes([
					{ label: 'Bike', value: 1, price: 5, distance: 0 },
				])
				if (!fromEdit) setproductVariant([])
			} else {
				setProductWorkflowTypes([
					{ label: 'Bike', value: 1, price: 5, distance: 0 },
					{ label: 'Bus', value: 2, price: 1, distance: 0 },
					{ label: 'Train', value: 3, price: 1, distance: 0 },
					{ label: 'Taxi', value: 4, price: 1, distance: 0 },
				])
				if (!fromEdit) setproductVariant([])
			}
		}
	}, [productCat])

	useEffect(() => {
		if (productVariant && productVariant.length > 0 && !fromEdit) {
			let total = productVariant.reduce(
				(s, f) => (!!f.productPrice ? s + Number(f.productPrice) : s + 0),
				0
			)
			setProductTotal(total)
		} else if (!fromEdit) {
			setProductTotal(null)
			onValidate(false)
		}
	}, [productVariant])

	useEffect(() => {
		const isVarSet = !!productVariant.map(i => i.distance).filter(Boolean)
			.length
		if (
			productTotal > -1 &&
			isVarSet &&
			productVariant.length > 0 &&
			!fromEdit
		) {
			onValidate(true)
			prepareFinalFieldValueForEdit(
				loadFinalValue(field, {
					productVariant,
					productTotal,
					productCat,
				}).final
			)
			dispatch({
				type: smartFormConst.SET_FIELD_VALUE,
				fieldId: field.field_id,
				formId: field.form_id,
				value: loadFinalValue(field, {
					productVariant,
					productTotal,
					productCat,
				}),
			})
			dispatch({
				type: smartFormConst.TO_REFRESH_NUM_VAL,
			})
		} else if (!fromEdit) {
			onValidate(false)
		}
	}, [productTotal, productVariant])

	const onQntyChange = (e, option) => {
		let variants = [...productVariant]
		let variantPrice = e.target.value * Number(option.price)
		let vIndex = productVariant.findIndex(
			v => Number(v.value) === Number(option.value)
		)
		if (vIndex > -1) {
			variants[vIndex] = {
				...variants[vIndex],
				productPrice: variantPrice,
				distance: e.target.value,
			}
		} else {
			variants.push({
				...option,
				productPrice: variantPrice,
				distance: e.target.value,
			})
		}
		setFromEdit(false)
		setproductVariant(variants)
	}

	return (
		<VStack spacing={2} w='full'>
			<Select
				className='full-width'
				name={field.field_id}
				id={`${field.field_name.split(' ').join('-')}-mode`}
				isDisabled={isDisabled}
				options={productCategories || []}
				placeholder='Select Mode'
				borderRadius='md'
				size='sm'
				value={productCat}
				menuPlacement={fieldIndex > 0 ? 'top' : 'bottom'}
				onChange={e => {
					setproductCat(e)
					setFromEdit(false)
				}}
			/>
			<Select
				isMulti={true}
				className='full-width'
				name={field.field_id}
				id={`${field.field_name.split(' ').join('-')}-transport`}
				isDisabled={isDisabled}
				options={productWorkflowTypes || []}
				placeholder='Select Transport'
				borderRadius='md'
				size='sm'
				value={productVariant}
				onChange={setproductVariant}
				menuPlacement={fieldIndex > 0 ? 'top' : 'bottom'}
			/>
			{!!productVariant && productVariant.length > 0 ? (
				<Table w='full'>
					<Thead position={'relative'} zIndex={1}>
						<Tr>
							<Th>{'Transport'}</Th>
							<Th>{'Price'}</Th>
							<Th>{'Distance / Amount'}</Th>
						</Tr>
					</Thead>
					<Tbody>
						{productVariant.map((p, index) => {
							return (
								<Tr key={index}>
									<Td>{p.label}</Td>
									<Td>{p.price}</Td>
									<Td>
										<Input
											size='sm'
											borderRadius='sm'
											type='number'
											min={0}
											value={p.distance ? p.distance : ''}
											onChange={e => onQntyChange(e, p)}
											isDisabled={isDisabled}
										/>
									</Td>
								</Tr>
							)
						})}
						<Tr>
							<Td>{'Total Value'} </Td>
							<Td></Td>
							<Td>{Number(productTotal)}</Td>
						</Tr>
					</Tbody>
				</Table>
			) : null}
		</VStack>
	)
}

export default ConveyanceField
