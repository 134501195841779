/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { AsyncSelect } from '../../../../chakra-react-select'
import {
	useLoadAssetRef,
	useLoadRoleAssetRef,
} from '../../../../../hooks/form.hooks'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import { useAuthContext } from '../../../../../context/auth.context'
import { useLanguageContext } from '../../../../../context/locale.context'
import { smartFormConst } from '../../../../../utils/action.constant'
import { customAssetSelectFormat } from './optionFormat'
import {
	getMessageUniqueId,
	toSnakeCase,
} from '../../../../../utils/common.util'

/**
 *
 *  dataTypeID : 59
 *  dataTypeName : Asset Reference
 */
const AssetReference = ({
	field,
	isDisabled = true,
	isRequired,
	onValidate,
	activity,
	isFromEdit,
	fieldIndex,
	scrollToBottom = () => {},
	onPrevious,
	fieldList,
}) => {
	const {
		state: { authData },
	} = useAuthContext()
	const { mutate: mutateAsset, data: assetData } = useLoadAssetRef()
	const { mutate: mutateRoleAsset, data: roleAssetData } = useLoadRoleAssetRef()

	const {
		state: { fields: formFields },
		dispatch,
	} = useSmartFormContext()
	const {
		state: { locale },
	} = useLanguageContext()
	const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {}
	const [isRoleBased, setisRoleBased] = useState(false)
	const {
		field_inline_data,
		field_id,
		form_id,
		field_reference_id,
		field_name,
		data_type_id,
		data_type_category_id,
	} = field

	let fieldValue = !!fields[field_id] ? fields[field_id].field_value : {}

	const onMountValidate = value => {
		if (!!isRequired) {
			if (!!value && Object.keys(value).length > 0) {
				onValidate(true)
			} else {
				onValidate(false)
			}
		} else {
			onValidate(true)
		}
	}

	/**
	 * The function `handleDependentFields` checks if the current field is a dependent field of some other field.
	 * If so, then it calls onPrevious method, so that whenever the current field changes, user has to start
	 * from submitting the current field again. This ensures that clicking "Continue" will retrieve the values
	 * of the dependent field.
	 */
	const handleDependentFields = () => {
		if (authData.organization_flag_enable_grid === 1) {
			let hasDependentField = fieldList?.some(
				item =>
					parseInt(item?.field_inline_data?.dependent_field_id) === field_id
			)
			if (hasDependentField) onPrevious(fieldIndex)
		}
	}

	const handleChange = value => {
		handleDependentFields()
		if (!!isRequired) {
			if (!!value && Object.keys(value).length > 0) {
				onValidate(true)
			} else {
				onValidate(false)
			}
		} else {
			onValidate(true)
		}
		const {
			asset_id,
			asset_first_name,
			operating_asset_id,
			operating_asset_first_name,
		} = value && !!value.details ? value.details : {}
		dispatch({
			type: smartFormConst.SET_FIELD_VALUE,
			fieldId: field.field_id,
			formId: field.form_id,
			value: {
				final: {
					form_id,
					field_id,
					field_reference_id,
					field_name,
					field_data_type_id: data_type_id,
					field_data_type_category_id: data_type_category_id,
					data_type_combo_id: 0,
					data_type_combo_value: '0',
					field_value: asset_id
						? `${asset_id}|${operating_asset_first_name}|${operating_asset_id}|${asset_first_name}`
						: '',
					message_unique_id: getMessageUniqueId(),
				},
				field_value: value,
			},
		})
	}

	const onChange = value => {
		if (!!isRequired) {
			if (!!value && Object.keys(value).length > 0) {
				onValidate(true)
			} else {
				onValidate(false)
			}
		} else {
			onValidate(true)
		}
		const {
			asset_id,
			asset_first_name,
			operating_asset_id,
			operating_asset_first_name,
		} = value && !!value.details ? value.details : {}

		dispatch({
			type: smartFormConst.SET_FIELD_VALUE,
			fieldId: field.field_id,
			formId: field.form_id,
			value: {
				final: {
					form_id,
					field_id,
					field_reference_id,
					field_name,
					field_data_type_id: data_type_id,
					field_data_type_category_id: data_type_category_id,
					data_type_combo_id: 0,
					data_type_combo_value: '0',
					field_value: asset_id
						? `${asset_id}|${operating_asset_first_name}|${operating_asset_id}|${asset_first_name}`
						: '',
					message_unique_id: getMessageUniqueId(),
				},
				field_value: value,
			},
		})
	}

	const buildRoleBaseAssetParams = query => {
		let asset_type_category_id = 0
		let asset_type_id = 0
		let filter_organization_id = 0
		let asset_ref_flag_filter = 0
		let asset_form_id = 0

		if (
			!!field_inline_data &&
			Object.keys(field_inline_data).length > 0 &&
			!!field_inline_data.asset_reference_restriction
		) {
			const { asset_reference_restriction: arefR } = field_inline_data
			asset_type_category_id = arefR.asset_type_category_id
			asset_type_id = arefR.asset_type_id
			// filter_organization_id = arefR.filter_organization_id;
			asset_ref_flag_filter = arefR.asset_ref_flag_filter
			asset_form_id = arefR.form_id || 0
		}

		let params = {
			search_string: query,
			// organization_id: filter_organization_id,
			activity_id: activity ? activity.activity_id : 0,
			flag_filter: asset_ref_flag_filter,
			asset_type_id,
			asset_type_category_id,
			start_from: 0,
			limit_value: 50,
		}
		if (Number(asset_ref_flag_filter) === 4) {
			params['form_id'] = asset_form_id
		}
		return params
	}

	const buildAssetRequestParams = (query = '') => {
		let asset_type_category_id = 0
		let flag = 0
		let asset_type_id = 0
		let asset_form_id = 0

		if (
			!!field_inline_data &&
			Object.keys(field_inline_data).length > 0 &&
			!!field_inline_data.asset_reference_restriction
		) {
			const { asset_reference_restriction: arefR } = field_inline_data
			asset_type_category_id = arefR.asset_type_category_id
			flag = arefR.asset_ref_flag_filter || 0
			asset_form_id = arefR.form_id || 0
			asset_type_id = arefR.asset_type_id
		}

		let params = {
			workforce_id: 0,
			flag_filter: flag, // 1 / 2,
			activity_id: activity ? activity.activity_id : 0,
			search_string: query,
			asset_type_category_id,
			asset_type_id,
		}

		if (Number(flag) === 4) {
			params['form_id'] = asset_form_id
		}
		return params
	}

	useEffect(() => {
		/*const {
      asset_reference_restriction: {
        asset_type_id,
        asset_ref_flag_filter,
        asset_flag_prefill_creator,
      },
    } = field_inline_data || { asset_reference_restriction: {} }*/
		// const {asset_reference_restriction}=field_inline_data||{}
		let { asset_reference_restriction } = {}
		if (!!field_inline_data?.asset_reference_restriction) {
			asset_reference_restriction =
				field_inline_data?.asset_reference_restriction
		}
		const { asset_type_id, asset_ref_flag_filter, asset_flag_prefill_creator } =
			asset_reference_restriction || {}
		////--------

		let value = {}
		if (!!field.data && Object.keys(field.data.field_value).length > 0) {
			value = field.data.field_value
			onChange(value)
		} else if (
			!!fields &&
			!!fields[field_id] &&
			!!fields[field_id].field_value &&
			!!fields[field_id].field_value.isFromEdit
		) {
			value = fields[field_id].field_value
			onChange(value)
		} else {
			if (
				!!asset_flag_prefill_creator &&
				Number(asset_flag_prefill_creator) !== 0 &&
				(fieldValue?.label !== authData.operating_asset_first_name ||
					(activity &&
						fieldValue?.label !==
							activity.activity_creator_operating_asset_first_name))
			) {
				if (Number(asset_flag_prefill_creator) === 1) {
					let {
						asset_id,
						operating_asset_first_name,
						asset_first_name,
						operating_asset_id,
						asset_phone_number,
						account_name,
					} = authData
					value = {
						label: operating_asset_first_name,
						value: asset_id,
						details: {
							asset_id: asset_id,
							asset_first_name: asset_first_name,
							operating_asset_first_name: operating_asset_first_name,
							operating_asset_id: operating_asset_id,
							operating_asset_phone_number: asset_phone_number,
							account_name: account_name,
						},
					}
				} else if (!!activity && Number(asset_flag_prefill_creator) === 2) {
					let {
						activity_creator_asset_first_name,
						activity_creator_asset_id,
						activity_creator_operating_asset_first_name,
						activity_creator_operating_asset_id,
						activity_creator_operating_asset_phone_number,
						account_name,
					} = activity
					value = {
						label: activity_creator_operating_asset_first_name,
						value: activity_creator_asset_id,
						details: {
							asset_id: activity_creator_asset_id,
							asset_first_name: activity_creator_asset_first_name,
							operating_asset_first_name:
								activity_creator_operating_asset_first_name,
							operating_asset_id: activity_creator_operating_asset_id,
							operating_asset_phone_number:
								activity_creator_operating_asset_phone_number,
							account_name: account_name,
						},
					}
				} else if (Number(asset_flag_prefill_creator) === 3) {
					mutateAsset(
						{
							...buildAssetRequestParams(''),
						},
						{
							onSuccess: async data => {
								const value = data[0]
								//onChange(value); //Bhanu asked not to select default
							},
						}
					)
				}
				onChange(value)
			} else if (
				asset_type_id &&
				![1, 2, 3].includes(Number(asset_ref_flag_filter))
			) {
				setisRoleBased(true)
				mutateRoleAsset({
					...buildRoleBaseAssetParams(''),
				})
			} else if ([2].includes(Number(asset_ref_flag_filter))) {
				setisRoleBased(true)
				mutateRoleAsset({
					...buildRoleBaseAssetParams(''),
				})
			} else if (!!asset_ref_flag_filter && asset_ref_flag_filter === 4) {
				mutateAsset(
					{
						...buildAssetRequestParams(''),
					},
					{
						onSuccess: async data => {
							const value = data[0]
							onChange(value)
						},
					}
				)
			} else if (!isFromEdit) {
				mutateAsset(
					{
						...buildAssetRequestParams(''),
					},
					{
						onSuccess: async data => {
							const value = data[0]
							//onChange(value); //Bhanu asked not to select default
						},
					}
				)
			}
		}
	}, [])

	useEffect(() => {
		if (
			!!fields &&
			!!fields[field_id] &&
			!!fields[field_id].field_value &&
			!!fields[field_id].field_value.isFromEdit
		) {
			let value = fields[field_id].field_value
			onMountValidate(value)
		}
	}, [formFields])

	const loadOptions = (value, callback) => {
		if (!!isRoleBased) {
			mutateRoleAsset(
				{
					...buildRoleBaseAssetParams(value),
				},
				{
					onSuccess: async res => {
						callback(res)
					},
				}
			)
		} else {
			mutateAsset(
				{
					...buildAssetRequestParams(value),
				},
				{
					onSuccess: async res => {
						callback(res)
					},
				}
			)
		}
	}

	return (
		<AsyncSelect
			name={field.field_id}
			className={`async-select-field-${toSnakeCase(
				field.field_name
			)} ${fieldValue}`}
			id={`${field.field_name.split(' ').join('-')}`}
			components={customAssetSelectFormat}
			isDisabled={isDisabled}
			placeholder={field.field_place_holder || `Enter ${field.field_name}`}
			controlShouldRenderValue={
				fieldValue && Object.keys(fieldValue)?.length === 0 ? false : true
			}
			borderRadius='md'
			size='sm'
			cacheOptions
			defaultOptions={(!!isRoleBased ? roleAssetData : assetData) || []}
			loadOptions={loadOptions}
			onChange={handleChange}
			value={fieldValue}
			maxMenuHeight='250'
			onInputChange={e => scrollToBottom('select-scroll')}
			onFocus={e => scrollToBottom('select-scroll')}
			// menuPlacement={
			//   field.next_field_id === -1 ? 'top' : fieldIndex > 2 ? 'top' : 'bottom'
			// }
		/>
	)
}

export default AssetReference
