/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { AsyncSelect } from '../../../../chakra-react-select'
import {
	useLoadAssetRef,
	useLoadRoleAssetRef,
} from '../../../../../hooks/form.hooks'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import { useLanguageContext } from '../../../../../context/locale.context'
import { smartFormConst } from '../../../../../utils/action.constant'
import { customAssetSelectFormat } from '../AssetReference/optionFormat'
import {
	getMessageUniqueId,
	toSnakeCase,
} from '../../../../../utils/common.util'

/**
 *  dataTypeID : 69
 *  dataTypeName : Multi Asset Reference
 */
const MultiAssetReference = ({
	field,
	isDisabled = true,
	isRequired,
	onValidate,
	fieldIndex,
	scrollToBottom = () => {},
}) => {
	const { mutate: mutateAsset, data: assetData } = useLoadAssetRef()
	const { mutate: mutateRoleAsset, data: roleAssetData } = useLoadRoleAssetRef()
	const [isRoleBased, setisRoleBased] = useState(false)
	const {
		field_id,
		form_id,
		field_reference_id,
		field_name,
		data_type_id,
		data_type_category_id,
		field_inline_data,
	} = field

	const {
		state: { fields: formFields },
		dispatch,
	} = useSmartFormContext()
	const {
		state: { locale },
	} = useLanguageContext()
	const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {}

	const onChange = value => {
		if (!!isRequired) {
			if (!!value) {
				onValidate(true)
			} else {
				onValidate(false)
			}
		} else {
			onValidate(true)
		}
		dispatch({
			type: smartFormConst.SET_FIELD_VALUE,
			fieldId: field.field_id,
			formId: field.form_id,
			value: {
				final: {
					form_id,
					field_id,
					field_reference_id,
					field_name,
					field_data_type_id: data_type_id,
					field_data_type_category_id: data_type_category_id,
					data_type_combo_id: 0,
					data_type_combo_value: '0',
					field_value: JSON.stringify(value.map(i => i.details)),
					message_unique_id: getMessageUniqueId(),
				},
				field_value: value,
			},
		})
	}

	const buildRoleBaseAssetParams = query => {
		let asset_type_category_id = 0
		let asset_type_id = 0
		let filter_organization_id = 0
		let asset_ref_flag_filter = 0
		let asset_form_id = 0

		if (
			!!field_inline_data &&
			Object.keys(field_inline_data).length > 0 &&
			!!field_inline_data.asset_reference_restriction
		) {
			const { asset_reference_restriction: arefR } = field_inline_data
			asset_type_category_id = arefR.asset_type_category_id
			asset_type_id = arefR.asset_type_id
			filter_organization_id = arefR.filter_organization_id
			asset_ref_flag_filter = arefR.asset_ref_flag_filter
			asset_form_id = arefR.form_id || 0
		}

		let params = {
			search_string: query,
			organization_id: filter_organization_id,
			activity_id: 0,
			flag_filter: 0,
			asset_type_id,
			asset_type_category_id,
			start_from: 0,
			limit_value: 50,
		}
		if (Number(asset_ref_flag_filter) === 4) {
			params['form_id'] = asset_form_id
		}
		return params
	}

	const buildAssetRequestParams = (query = '') => {
		let asset_type_category_id = 0
		let flag = 0
		let asset_type_id = 0
		let asset_form_id = 0

		if (
			!!field_inline_data &&
			Object.keys(field_inline_data).length > 0 &&
			!!field_inline_data.asset_reference_restriction
		) {
			const { asset_reference_restriction: arefR } = field_inline_data
			asset_type_category_id = arefR.asset_type_category_id
			flag = arefR.asset_ref_flag_filter || 0
			asset_form_id = arefR.form_id || 0
			asset_type_id = arefR.asset_type_id
		}

		let params = {
			workforce_id: 0,
			flag_filter: flag, // 1 / 2,
			activity_id: 0,
			search_string: query,
			asset_type_category_id,
			asset_type_id,
		}

		if (Number(flag) === 4) {
			params['form_id'] = asset_form_id
		}
		return params
	}

	useEffect(() => {
		// const {
		//   asset_reference_restriction: { asset_type_id, asset_ref_flag_filter },
		// } = field_inline_data || {}
		const { asset_type_id, asset_ref_flag_filter } =
			(field_inline_data && field_inline_data?.asset_reference_restriction) ||
			{}
		if (asset_type_id && asset_ref_flag_filter !== 3) {
			setisRoleBased(true)
			mutateRoleAsset({
				...buildRoleBaseAssetParams(''),
			})
		} else {
			mutateAsset({
				...buildAssetRequestParams(''),
			})
		}
	}, [])

	useEffect(() => {
		if (
			!!fields[field.field_id] &&
			Array.isArray(fields[field.field_id].field_value)
		) {
			if (fields[field.field_id].field_value.length > 0) {
				onValidate(true)
			}
		}
	}, [fields[field.field_id]])

	const loadOptions = (value, callback) => {
		if (!!isRoleBased) {
			mutateRoleAsset(
				{
					...buildRoleBaseAssetParams(value),
				},
				{
					onSuccess: async res => {
						callback(res)
					},
				}
			)
		} else {
			mutateAsset(
				{
					...buildAssetRequestParams(value),
				},
				{
					onSuccess: async res => {
						callback(res)
					},
				}
			)
		}
	}

	return (
		<AsyncSelect
			className={`multi-reference-assets-${toSnakeCase(field.field_name)}`}
			name={field.field_id}
			id={`${field.field_name.split(' ').join('-')}`}
			components={customAssetSelectFormat}
			isDisabled={isDisabled}
			placeholder={field.field_place_holder || `Enter ${field.field_name}`}
			borderRadius='md'
			size='sm'
			closeMenuOnSelect={false}
			isMulti={true}
			cacheOptions
			defaultOptions={(!!isRoleBased ? roleAssetData : assetData) || []}
			loadOptions={loadOptions}
			onChange={onChange}
			value={fields[field.field_id] ? fields[field.field_id].field_value : []}
			onInputChange={e => scrollToBottom('select-scroll')}
			onFocus={e => scrollToBottom('select-scroll')}
			// menuPlacement={
			//   field.next_field_id === -1 ? 'top' : fieldIndex > 2 ? 'top' : 'bottom'
			// }
		/>
	)
}

export default MultiAssetReference
