/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
	VStack,
	Textarea,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
} from '@chakra-ui/react'
import Select from '../../../../chakra-react-select'
import { debounce, values } from 'lodash'
import { useSearchWorkflowByTags } from '../../../../../hooks/form.hooks'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import { loadFinalValue } from '../../../../../utils/form.util'
import { smartFormConst } from '../../../../../utils/action.constant'
const TransactionOptions = [
	{
		label: 'Please Select Account',
		value: '-1',
		target: {
			value: '-1',
		},
		persist: () => null,
	},
	{
		label: 'Credit',
		value: '1',
		target: {
			value: '1',
		},
		persist: () => null,
	},
	{
		label: 'Debit',
		value: '2',
		target: {
			value: '2',
		},
		persist: () => null,
	},
]

const AccountCreditDebitTransaction = ({
	field,
	isDisabled = true,
	isRequired,
	onValidate,
	fieldList,
}) => {
	const {
		state: { fields: formFields },
		dispatch,
	} = useSmartFormContext()
	const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {}

	const { mutate, data } = useSearchWorkflowByTags()
	const [selectedFile, setSelectedFile] = useState()
	const [transactionTypeId, setTransactionTypeId] = useState()
	const [transactionAmount, setTransactionAmount] = useState(0)
	const [transactionNote, setTransactionNote] = useState()
	const workflowSearchDebounce = useCallback(
		debounce(params => {
			mutate(params)
		}, 500),
		[]
	)
	useEffect(() => {
		if (
			!!fields[field.field_id] &&
			!!fields[field.field_id].field_value &&
			!!fields[field.field_id].field_value.isFromEdit
		) {
			let savedData = fields[field.field_id].field_value.value
			if (!!savedData && !!savedData.transaction_data) {
				let finalData = savedData.transaction_data
				setTransactionAmount(finalData.transaction_amount)
				setTransactionNote(finalData.transaction_note)
				setTransactionTypeId({
					label: finalData.transaction_type_name,
					value: finalData.transaction_type_id,
				})
				setSelectedFile({
					label: finalData.activity_name,
					value: finalData.activity_id,
				})
			}
		}
	}, [fields[field.field_id]])

	const buildWorkflowsRequestParams = srchStr => {
		let txtToSrch = ''
		if (srchStr && srchStr !== '') {
			txtToSrch = srchStr
		}
		let activityTypeId = 0
		let tagId = 0
		let tagTypeId = 0
		let isParticipant = 0
		let activityStatusTypeId = 0
		let flagDependent = 0
		let dependentFieldId = 0
		let dependentFieldServiceName = ''
		const { bots, workflow_reference_restriction } = field.field_inline_data
		if (workflow_reference_restriction) {
			const {
				activity_type_id,
				tag_id,
				tag_type_id,
				flag_participating,
				activity_status_type_id,
				flag_dependent,
				dependent_field_id,
				dependent_field_service_param_name,
			} = workflow_reference_restriction
			activityTypeId = activity_type_id || 0
			tagId = tag_id || 0
			tagTypeId = tag_type_id || 0
			isParticipant = flag_participating || 0
			activityStatusTypeId = activity_status_type_id || 0
			flagDependent = flag_dependent || 0
			dependentFieldId = dependent_field_id || 0
			dependentFieldServiceName = dependent_field_service_param_name || ''
		}
		let params
		if (flagDependent === 0) {
			params = {
				workforce_id: 0,
				search_string: txtToSrch,
				service_version: 0.1,
				app_version: 1.0,
				flag_status: 0,
				flag_participating: isParticipant,

				device_os_id: 5,
				page_limit: 50,
				page_start: 0,
				activity_type_category_id: 48,
				activity_type_id: activityTypeId,
				tag_id: tagId,
				tag_type_id: tagTypeId,
				activity_status_type_id: activityStatusTypeId,
			}
		} else {
			params = {
				search_string: txtToSrch,
				service_version: 0.1,
				app_version: 1.0,
				flag_status: 0,
				flag_participating: isParticipant,
				device_os_id: 5,
				page_limit: 50,
				page_start: 0,
				activity_type_category_id: 48,
				activity_type_id: activityTypeId,
				tag_id: tagId,
				tag_type_id: tagTypeId,
				activity_status_type_id: activityStatusTypeId,
				flag_dependent: flagDependent,
			}
			if (!!fieldList[`${dependentFieldId}i`]) {
				const data = fieldList[`${dependentFieldId}i`].fileUploadData
				params[`${dependentFieldServiceName}`] = data.value
			}
		}
		return params
	}
	const inputWorkflowSelectChange = (e, elemenType) => {
		const srchStr = e
		workflowSearchDebounce(buildWorkflowsRequestParams(srchStr))
	}
	const onChangeTransactionNote = value => {
		setTransactionNote(value)
		if (!!selectedFile && !!transactionTypeId && !!transactionAmount) {
			onValidate(true)
		} else {
			onValidate(false)
		}
		const { workflow_reference_restriction } = field.field_inline_data
		let formatted_value = {
			transaction_data: {
				activity_id: selectedFile.value,
				activity_name: selectedFile.label,
				activity_type_id: !!workflow_reference_restriction
					? workflow_reference_restriction.activity_type_id
					: '',
				activity_type_name: !!workflow_reference_restriction
					? workflow_reference_restriction.activity_type_name
					: '',
				transaction_type_id: transactionTypeId.value,
				transaction_type_name: transactionTypeId.label,
				transaction_amount: transactionAmount,
				transaction_note: value,
				transaction_datetime: new Date()
					.toJSON()
					.slice(0, 19)
					.replace('T', ' '),
				transaction_location_data: {
					location_latitude: 0,
					location_longitude: 0,
					location_gps_enabled: 1,
					location_accuracy: '',
					location_address: '',
				},
			},
		}
		dispatch({
			type: smartFormConst.SET_FIELD_VALUE,
			fieldId: field.field_id,
			formId: field.form_id,
			value: loadFinalValue(field, formatted_value),
		})
	}
	return (
		<VStack spacing={1} d='flex' w='full'>
			<Select
				id={`search-by-work-flow-name-lead-search`}
				className='full-width'
				name='lead_search'
				placeholder='Search by Workflow name'
				isSearchable={true}
				// isDisabled={isDisabled}
				onInputChange={e => inputWorkflowSelectChange(e, field.data_type_id)}
				options={data}
				onChange={e => {
					setSelectedFile(e)
				}}
				value={selectedFile}
			/>
			<Select
				id={`search-by-work-flow-name-transaction-type`}
				className='full-width'
				isSearchable={true}
				// isDisabled={!selectedFile}
				options={TransactionOptions}
				value={transactionTypeId}
				onChange={e => {
					setTransactionTypeId(e)
				}}
			/>
			<NumberInput
				onChange={e => setTransactionAmount(e)}
				value={transactionAmount}
				isDisabled={isDisabled}
				placeholder='Document Version'
				w='full'
			>
				<NumberInputField />
				<NumberInputStepper>
					<NumberIncrementStepper />
					<NumberDecrementStepper />
				</NumberInputStepper>
			</NumberInput>
			<Textarea
				type='text'
				isDisabled={isDisabled}
				placeholder={`Enter Document Description`}
				borderRadius='md'
				size='sm'
				rows={3}
				resize='vertical'
				value={transactionNote}
				onChange={e => onChangeTransactionNote(e.target.value)}
			/>
		</VStack>
	)
}

AccountCreditDebitTransaction.propTypes = {
	field: PropTypes.object.isRequired,
	fieldList: PropTypes.array.isRequired,
}

export default AccountCreditDebitTransaction
