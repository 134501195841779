/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-dupe-keys */
import React, { useEffect } from 'react'
import { useLoadWorkflowRef } from '../../../../../hooks/form.hooks'
import { AsyncSelect } from '../../../../chakra-react-select'
import { customSelectComponents } from '../WorkflowReference/optionFormat'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import { smartFormConst } from '../../../../../utils/action.constant'
import {
  getMessageUniqueId,
  toSnakeCase,
} from '../../../../../utils/common.util'
import { useAuthContext } from '../../../../../context/auth.context'
import { useLanguageContext } from '../../../../../context/locale.context'
import { useSharedContext } from '../../../../../context/shared.context'
import ParticipantsView from '../WorkflowReference/ParticipantsView'

/**
 * datatypeId 68
 * datatypename  Multi Workflow Reference
 */
const MultiWorkflowReference = ({
  field,
  isDisabled = true,
  isRequired,
  onValidate,
  fieldIndex,
  scrollToBottom = () => {},
}) => {
  const { mutate, data } = useLoadWorkflowRef()
  const {
    field_id,
    form_id,
    field_reference_id,
    field_name,
    data_type_id,
    data_type_category_id,
    field_inline_data,
  } = field

  const {
    state: { fields: formFields, isModelOpen },
    dispatch,
  } = useSmartFormContext()

  const {
    state: { onFlyDataId, onFlyAddData, onFlyFieldId },
  } = useSharedContext()

  const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {}

  const {
    state: { authData },
  } = useAuthContext()
  const {
    state: { locale },
  } = useLanguageContext()
  const onChange = value => {
    let selectionLim =
      field_inline_data?.workflow_reference_restriction
        ?.multi_selection_max_limit
    if (selectionLim || Number(selectionLim) !== 0) {
      if (value.length > Number(selectionLim)) {
        return
      }
    }
    if (!!isRequired) {
      if (!!value && value.length > 0) {
        onValidate(true)
      } else {
        onValidate(false)
      }
    } else {
      onValidate(true)
    }
    dispatch({
      type: smartFormConst.SET_FIELD_VALUE,
      fieldId: field.field_id,
      formId: field.form_id,
      value: {
        final: {
          form_id,
          field_id,
          field_reference_id,
          field_name,
          field_data_type_id: data_type_id,
          field_data_type_category_id: data_type_category_id,
          data_type_combo_id: 0,
          data_type_combo_value: '0',
          field_value: JSON.stringify(value.map(i => i.details_web)),
          message_unique_id: getMessageUniqueId(),
        },
        field_value: value,
      },
    })
  }

  useEffect(() => {
    if (
      !!onFlyDataId &&
      onFlyDataId === 68 &&
      !!onFlyAddData && !!onFlyAddData?.activity_id &&
      onFlyFieldId === field_id
    ) {
      const {
        activity_id,
        activity_title,
        activity_cuid_1,
        activity_cuid_2,
        activity_cuid_3,
        activity_creator_operating_asset_first_name,
        account_name,
      } = onFlyAddData
      onChange([
        {
          label: onFlyAddData.activity_title,
          value: `${onFlyAddData.activity_id}|${onFlyAddData.activity_title}`,
          details: {
            activity_id,
            activity_title,
            activity_cuid_1,
            activity_cuid_2,
            activity_cuid_3,
            operating_asset_first_name:
              activity_creator_operating_asset_first_name,
            account_name,
          },
          details_web: {
            workflow_activity_id: activity_id,
            workflow_activity_title: activity_title,
            activity_cuid_1,
            activity_cuid_2,
            activity_cuid_3,
            operating_asset_first_name:
              activity_creator_operating_asset_first_name,
            account_name,
          },
        },
      ])
    }
  }, [onFlyDataId, onFlyAddData, onFlyFieldId])

  const buildWorkflowsRequestParams = (query = '') => {
    let activityTypeId = 0
    let tagId = 0
    let tagTypeId = 0
    let isParticipant = 0
    let activityStatusTypeId = 0
    let activityStatusId = 0
    let flagDependent = 0
    let dependentFieldId = 0
    let dependentFieldServiceName = ''
    let activity_type_categoryId = 0
    let assetReferenceFieldId = ''
    if (
      !!field_inline_data &&
      Object.keys(field_inline_data).length > 0 &&
      field_inline_data.workflow_reference_restriction
    ) {
      const {
        activity_type_id,
        tag_id,
        tag_type_id,
        flag_participating,
        activity_status_type_id,
        flag_dependent,
        dependent_field_id,
        dependent_field_service_param_name,
        activity_type_category_id,
        asset_reference_field_id,
        activity_status_id,
      } = field_inline_data.workflow_reference_restriction
      activityTypeId = activity_type_id || 0
      tagId = tag_id || 0
      tagTypeId = tag_type_id || 0
      isParticipant = flag_participating || 0
      activityStatusTypeId = activity_status_type_id || 0
      activityStatusId = activity_status_id || 0
      flagDependent = flag_dependent || 0
      dependentFieldId = dependent_field_id || 0
      dependentFieldServiceName = dependent_field_service_param_name || ''
      activity_type_categoryId = activity_type_category_id
      assetReferenceFieldId = asset_reference_field_id || ''
    }
    let refAssetId = ''
    if (assetReferenceFieldId) {
      refAssetId = fields[assetReferenceFieldId]?.field_value.details.asset_id
    }

    let params = {
      search_string: query,
      flag_status: 0,
      flag_participating: isParticipant,
      activity_type_category_id: 48,
      activity_type_id: activityTypeId,
      tag_id: tagId,
      tag_type_id: tagTypeId,
      activity_status_type_id: activityStatusTypeId,
      activity_status_id: activityStatusId,
      activity_type_category_id: activity_type_categoryId,
      reference_asset_id: refAssetId,
    }
    if (flagDependent && !!fields[dependentFieldId]) {
      params = {
        ...params,
        flag_dependent: flagDependent,
        isElasticSearchEnabled:
          authData.organization_flag_elasticsearch_enabled,
        [`${dependentFieldServiceName}`]:
          fields[dependentFieldId].field_value.details.activity_id,
      }
    }

    return params
  }

  useEffect(() => {
    mutate({
      isElasticSearchEnabled: authData.organization_flag_elasticsearch_enabled,
      ...buildWorkflowsRequestParams(''),
    })
  }, [])

  useEffect(() => {
    if (
      !!fields[field.field_id] &&
      Array.isArray(fields[field.field_id].field_value)
    ) {
      if (fields[field.field_id].field_value.length > 0) {
        onValidate(true)
      }
    }
  }, [fields[field.field_id]])

  const loadOptions = (value, callback) => {
    mutate(
      {
        isElasticSearchEnabled:
          authData.organization_flag_elasticsearch_enabled,
        ...buildWorkflowsRequestParams(value),
      },
      {
        onSuccess: async res => {
          callback(res)
        },
      }
    )
  }
  return (
    <>
      <AsyncSelect
        className={`multi-workflow-reference-${toSnakeCase(field.field_name)}`}
        isMulti={true}
        name={field.field_id}
        id={`${field.field_name.split(' ').join('-')}`}
        components={customSelectComponents}
        isDisabled={isDisabled}
        placeholder={field.field_place_holder || `Enter ${field.field_name}`}
        borderRadius="md"
        size="sm"
        closeMenuOnSelect={false}
        cacheOptions
        defaultOptions={data || []}
        loadOptions={loadOptions}
        onChange={onChange}
        value={fields[field.field_id] ? fields[field.field_id].field_value : []}
        onInputChange={e => scrollToBottom('select-scroll')}
        onFocus={e => scrollToBottom('select-scroll')}
        // menuPlacement={
        //   field.next_field_id === -1 ? 'top' : fieldIndex > 2 ? 'top' : 'bottom'
        // }
      />

      {isModelOpen && <ParticipantsView />}
    </>
  )
}

export default MultiWorkflowReference
